// 账本基本字段
let tableData = [
  {
    label: '序号',
    type: 'index',
    align: 'center',
    show: true,
    width: 70
  },
  {
    label: '分片名',
    prop: 'shardName',
    show: true,
    align: 'center',
    width: 120
  },
  {
    label: '账本类型',
    prop: 'ledgerCodeName',
    show: true,
    align: 'center',
    width: 100
  },
  {
    label: '区块高度',
    prop: 'blockHeight',
    show: true,
    enableSlot: true,
    align: 'center',
    width: 100
  },
  {
    label: '账本哈希',
    prop: 'hash',
    show: true,
    enableSlot: true,
    minWidth: 120
  },
  {
    label: '创建地址',
    prop: 'sendAddress',
    show: true,
    enableSlot: true,
    minWidth: 120
  },
  {
    label: '时间戳',
    prop: 'timestamp',
    show: true,
    enableSlot: true,
    width: 190
  },
  {
    label: '备注',
    prop: 'remark',
    show: true,
    enableSlot: true,
    width: 160
  },
  {
    prop: 'operators',
    label: '操作',
    fixed: 'right',
    align: 'right',
    width: 100,
    children: [
      {
        icon: 'el-icon-view',
        label: '查看',
        clickEvent: (thisVue, row) => {
          thisVue.handleDetail(row.hash)
        },
        showFilter: () => {
          return true
        }
      }
    ]
  }
]

// 记账单位额外字段
let token = [
  {
    label: '接收者地址',
    prop: 'receiveAddress',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '记账单位名称',
    prop: 'name',
    align: 'center',
    show: true,
    width: 100
  },
  {
    label: '记账单位标识符',
    prop: 'unit',
    align: 'center',
    show: true,
    width: 110
  },
  {
    label: '记账单位总数',
    prop: 'count',
    align: 'center',
    show: true,
    width: 100
  }
]

// 交易额外字段
let transaction = [
  {
    label: '接收者地址',
    prop: 'receiveAddress',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '记账单位哈希',
    prop: 'token',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '记账单位数量',
    prop: 'value',
    align: 'center',
    show: true,
    width: 100
  }
]

// 非同质化记账单位账本额外字段
let tokenNFT = [
  {
    label: '接收者地址',
    prop: 'receiveAddress',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '个性凭证名称',
    prop: 'name',
    align: 'center',
    show: true,
    width: 150
  },
  {
    label: '个性凭证数量',
    prop: 'value',
    align: 'center',
    show: true,
    width: 100
  },
  {
    label: '个性凭证哈希',
    prop: 'token',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '拥有者',
    prop: 'owner',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '最新流转记录',
    prop: 'txHash',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '是否销毁',
    prop: 'reCall',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '销毁记录',
    prop: 'daHash',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  }
]

// 非同质化记账单位交易账本额外字段
let transactionNFT = [
  {
    label: '接收者地址',
    prop: 'receiveAddress',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '个性凭证哈希',
    prop: 'token',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '上一笔交易记录',
    prop: 'parentHash',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  }
]

// 非同质化记账单位销毁账本额外字段
let nftDamage = [
  {
    label: '接收者地址',
    prop: 'receiveAddress',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '记账单位哈希',
    prop: 'token',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  }
]

// 质押账本额外字段
let deposit = [
  {
    label: '接收者地址',
    prop: 'receiveAddress',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '记账单位哈希',
    prop: 'token',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '赎回时间区间',
    prop: 'redemptionTime',
    align: 'center',
    show: true,
    minWidth: 200
  },
  {
    label: '赎回时间',
    prop: 'redemptionTimestamp',
    align: 'center',
    show: true,
    enableSlot: true,
    minWidth: 200
  },
  {
    label: '质押数量',
    prop: 'value',
    align: 'center',
    show: true
  },
  {
    label: '是否赎回',
    prop: 'reCall',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '赎回哈希',
    prop: 'reHash',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  }
]

// 赎回账本额外字段
let redeem = [
  {
    label: '质押哈希',
    prop: 'depositHash',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '接收者地址',
    prop: 'receiveAddress',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '记账单位哈希',
    prop: 'token',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '赎回数量',
    prop: 'value',
    align: 'center',
    show: true
  }
]

// 存证账本额外字段
let chainData = [
  {
    label: '父存证账本哈希',
    prop: 'parentHash',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '数据来源',
    prop: 'dataSources',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '存证数据',
    prop: 'message',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  }
]

// 状态账本额外字段
let statusData = [
  {
    label: '状态索引',
    prop: 'statusKey',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '状态数据',
    prop: 'json',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  },
  {
    label: '状态版本号',
    prop: 'statusVersion',
    align: 'center',
    show: true,
    width: 100
  },
  {
    label: '上一次修改记录',
    prop: 'parentHash',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 240
  }
]

// 合约账本额外字段
let contract = [
  {
    label: '合约语言',
    prop: 'languages',
    align: 'center',
    show: true
  },
  {
    label: '合约代码',
    prop: 'contract',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 100
  }
]

let headData = {
  title: '账本列表'
}

let tableObj = {
  'tableData': tableData,
  'headData': headData,
  'token': token, // 101
  'transaction': transaction, // 102
  'tokenNFT': tokenNFT, // 106
  'transactionNFT': transactionNFT, // 107
  'nftDamage': nftDamage, // 1
  'deposit': deposit, // 104
  'redeem': redeem, // 105
  'chainData': chainData, // 103
  'statusData': statusData, // 108
  'contract': contract // 201
}

export default tableObj
